body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .myCustomCss {} */
.emojionearea,
.emojionearea.form-control > #emojionearea1 {
  max-width: 85% !important;
  border-radius: 0px 0px 0px 0px !important;
  /* margin-top: -1px !important; */
  padding: 0 !important;
  /* border: 1px solid #fff !important; */
  font-size: 13.3px !important;
  /* border: none !important; */
  box-shadow: none !important;
  /* background-color: #dfdfdf;*/
  border-bottom: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-top: 1px solid white !important;
  z-index: 0;
}

.emojionearea.focused {
  border: 1px solid #66afe9 !important;
}

/* div + button.MuiButtonBase-root.MuiIconButton-root {
  margin-top: -30px !important;
  margin-left: 4px !important;
}

div + button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-root:hover{
  background-color: unset !important;
} */

/* .MuiMenu-paper{
  top: 684px !important;
}  */

/*Menu adjuntar archivos y audios*/
#simple-menu-files .MuiMenuItem-root {
  /* background-color: aliceblue; */
  /* display: block; */
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
}

#simple-menu-files .MuiMenu-paper {
  background: unset;
  box-shadow: unset;
}

/*AudioBox*/
#audioRecBoxWebChat .MuiMenu-paper {
  margin-left: -106px !important;
  margin-top: 8 5px;
  box-shadow: unset;
  /* background: rgb(112, 103, 103); */
  top: calc(100vh - 80px) !important;
  left: 120px !important;
  width: 100vh !important;
  /* border: 1px solid rgb(218 218 218);
  border-radius: 50px; */
}
#audioRecBoxWebChat .MuiListItem-button:hover{
  background-color: unset !important
}
#audioRecBoxWebChat .MuiMenuItem-root {
  border-radius: 50%;
  height: 50px;
}

#audioRecBoxWebChat ul {
  display: flex !important;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

/* #audioRecBoxWebChat ul li:nth-child(1):hover {
  color: white !important;
  background: red;
} */

#audioRecBoxWebChat ul li:nth-child(2) svg {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 50%;
  background: red;
  font-size: 18px !important;
}
#audioRecBoxWebChat ul li:nth-child(2) .MuiTouchRipple-root {
  position: unset !important;
}
/*contador de segundos*/
#audioRecBoxWebChat ul li:nth-child(2):hover {
  color: black !important;
  background: unset !important;
  cursor: default !important;
}

/*Boton Enviar*/
/* #audioRecBoxWebChat ul li:nth-child(3):hover {
  color: white !important;
  background: lightgreen;
} */

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 3px 0px rgba(173, 0, 0, 0.3);
  }

  65% {
    box-shadow: 0px 0px 3px 3px rgba(173, 0, 0, 0.3);
  }

  90% {
    box-shadow: 0px 0px 10px 0px rgba(173, 0, 0, 0);
  }
}
/*---- Fin Menu adjuntar archivos y audios ----*/

/*scrollChat*/
/* .styles_scrollable-div__prSCv::-webkit-scrollbar {
  display: none !important;
} */

/* custom scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/*imagenMensajes*/
.rce-mbox-photo--img img {
  height: 100%;
  min-height: 100px;
  -webkit-user-select: none;
  user-select: none;
  width: 100% !important;
  max-width: 256px !important;
  max-height: 256px !important;
}

.Callout {
  /* padding-top: 145px; */
  opacity: 1;
  transform: none;
  /* width: 75px; */
  /* height: 480px; */
  transition: opacity 353ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 235ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* margin-left: 50px; */
  margin-bottom: 20px;
}

/* .Callout div[class~="auxCallout"] {
  
    padding-left: 20px;
    padding-right: 30px;
    box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px;
    height: 70px;
    width: max-content;
    border-radius: 50px;
    background-color: white;
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    transform: translateX(-120px);
} */

.Callout div[class~="auxCallout"]{
  padding-left: 20px;
    padding-right: 30px;
    box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 23%);
    height: 75px;
    border-radius: 50px;
    background-color: white;
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
}

.closeCallout{
  /* position: absolute;
  top: 5px;
  
  z-index: 1;
  cursor: pointer;
  display: block;
  right: 25px; */
  z-index: 1;
  cursor: pointer;
  display: flex;
  
}
.closeCalloutLeft{
  /* position: absolute;
  top: 5px;
  
  z-index: 1;
  cursor: pointer;
  display: block;
  left: 230px; */

  z-index: 1;
  cursor: pointer;
  display: flex;
  
}

.Callout:hover .closeCallout {
  display: block
}

.rce-citem-avatar img{
  background: transparent !important
}

/* .rce-mbox {
  background: f3f3f3 !important;
}
.rce-mbox-left-notch{
  fill: f3f3f3 !important;

} */
.boxRecieveMsg > div {
  background: white
}
.boxSendMsg > div{
  background: black

}

.MuiIconButton-root:hover{
  background-color: unset !important
}

.rce-mbox.rce-mbox-right {
  background: #D3EDD0;
  max-width: 80%;
  
}

.rce-mbox-file > button > *{
  max-width: 45vh !important;
}
.rce-mbox-right-notch{
  fill: #D3EDD0 !important;
  
}
.rce-mbox-text{
  white-space: break-spaces;
}


.growLeft{
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 100%;
}  
.growRight{
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 100%;
}  
.messageBox{
  overflow: hidden;
  height: calc(100vh - 310px);
}
.messageBoxSinLogo{
  height: calc(100vh - 310px);
  overflow: hidden;
}
.messageBox_Scrollbars{
  position: relative;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    height: 90%;
}
.messageBox_Scrollbars_SinLogo{
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  height: 90%;
}

.growPhoneLeft{
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
} 
.growPhoneRight{
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 100%;
}   
.divCont{
  opacity: 1;
  transform: none;
  width: calc(100% - 10px);
  height: calc(100vh - 10px);
  background-color: rgb(253, 255, 252);
  box-shadow: rgb(0 0 0 / 23%) 0px 1px 6px;
  border-top-left-radius: 12px;
  transition: opacity 353ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 235ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-top-right-radius: 12px;
  /* margin-bottom: -15px; */
  margin: 5px;
  position: relative;
}

.divContPhone{
  width: 100%;
  height: 100vh;
  margin: 0px;

  opacity: 1;
  transform: none;
  background-color: rgb(253, 255, 252);
  box-shadow: rgb(0 0 0 / 23%) 0px 1px 6px;
  border-top-left-radius: 12px;
  transition: opacity 353ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 235ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-top-right-radius: 12px;
  position: relative;
}
.messageBoxPhone{
  overflow: hidden;
  height: calc(100vh - 310px);
}
.messageBoxSinLogoPhone{
  overflow: hidden;
  height: calc(100vh - 310px);
}
.messageBox_Scrollbars_Phone{
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  height: 90%;
}
.messageBox_Scrollbars_SinLogo_Phone{  
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  height: 90%;
}

.messageBox_Prechat_Phone{
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}
.messageBox_Prechat {
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}
.messageBox_Chat_Phone{
  height: 100%;
  max-height: calc(100vh - 160px);
}
.messageBox_Chat{
  height: 100%;
    max-height: calc(100vh - 160px);
    background-image: url('/img/background/chatbcg7.svg');

}

.messageBox_Encuesta_Phone{ 
  height: calc(100vh - 155px)
}
.messageBox_Encuesta_Iframe{
  width: auto;
  border: none;
  height: calc(100vh - 170px)
}
.messageBox_Encuesta_Iframe_Phone{
  width: auto;
  border: none;
  height: 100%
}
.boxIconCanales {

  display: flex;
  margin-top: -10px;
  border-bottom: 1px solid lightgray;
  background: white;
  justify-content: flex-end;
  padding-right: 15px;
  /* height: 22px; */
  padding-top:3px;
  padding-bottom:3px

}
.boxIconCanales_Phone{
  margin-top: -10px;
    background: white;
    height: 25px;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}

.iconWS_Phone{
  width: 22px;
  margin-top: 2px;
}
.iconMG_Phone{
  width: 21px;
  margin-top: 2px;
}
.iconTubePhone_Phone{

    width: 20px;
    margin-top: 2px;
    margin-left: 5px;
    cursor: pointer;
    image-rendering: -webkit-optimize-contrast;
}
.iconWS{
  height: 22px;
  width: 22px;
  vertical-align: text-bottom;
  image-rendering: -webkit-optimize-contrast;
}



.iconMG{
  width: 21px;
  height: 21px;
  vertical-align: text-bottom;
  margin-top: 1px;
  /* image-rendering: -webkit-optimize-contrast; */

}
.iconTubePhone{
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
  /* position: absolute; */
  right: 18px;
  margin-bottom: 1px;
  image-rendering: -webkit-optimize-contrast;
}

.btnNuevoChat{
  position: -webkit-sticky;
  position: sticky;
    bottom: 0px;
    top: 250px;
    left: calc(100% - 400px);
    right: 0;
    background: #ffffff;
    padding: 20px;
    text-align: center;
}
.btnNuevoChat_Phone {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  top: 250px;
  left: calc(100% - 400px);
  right: 0;
  background: #ffffff;
  padding: 20px;
  text-align: center;
}

.chat-icon-right{
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.boxChatChannels{
background: white;
border: 1px solid lightgrey;
height: 72%;
z-index: 2;
position: absolute;
width: calc(100% - 32px);
top: 112px;
margin: 10px;
box-shadow: 0px 0px 10px 0px rgb(0 0 0);
}
.boxChatChannels_Phone{
  background: white;
  border: 1px solid lightgrey;
  height: 80%;
  z-index: 2;
  position: absolute;
  width: calc(100% - 22px);
  top: 112px;
  margin: 10px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0)
}
.boxChatChannelsLogo{
  background: white;
    border: 1px solid lightgrey;
    height: calc(100vh - 180px);
    z-index: 2;
    position: absolute;
    width: calc(100% - 32px);
    top: 154px;
    margin: 10px;
    box-shadow: 0px 0px 10px 0px rgb(110, 102, 102);
}
.boxChatChannelsLogo_Phone{
  background: white;
    border: 1px solid lightgrey;
    height: calc(100vh - 180px);
    z-index: 2;
    position: absolute;
    width: calc(100% - 22px);
    top: 154px;
    margin: 10px;
    box-shadow: 0px 0px 10px 0px rgb(110 102 102);
}

div[class*="messageBox_Scrollbars_1"] div[style*="position: absolute; height: 6px; transition: opacity 200ms ease 0s; opacity: 0; right: 2px; bottom: 2px; left: 2px; border-radius: 3px;"] div{
  background-color: unset !important;
}

.rce-container-citem-channelInfo{
  border: 1px solid lightgrey;
    margin: 0px 10px;
    margin-bottom: 15px !important;
}
.colorGrayO50{
  color: #8080808f;
}

.iconChannelInfo{
  position: absolute;
    right: 15px;
    top: 23px;
}

.titleSinLogo{
  margin-left: 25px;
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px
}
.titleConLogo{
  margin-left: 25px;
  margin-top: -5px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px
}

.widgetStyle {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 6px 6px;
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  color: var(--colorLetra) !important;
  background: #ffffff;
  background-image: linear-gradient(var(--widgetContainer), var(--widgetContainer2)) !important;
  cursor: pointer; }

.widgetStyleText {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 6px 6px;
  min-width: 150px;
  height: 70px;
  background: #ffffff;
  background-image: linear-gradient(to right, var(--widgetContainer) 50%, var(--widgetContainer2)) !important;
  color: var(--colorLetra) !important;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 45px; }

/* Container */
.container-item {
    flex-direction: column;
    display: block;
    overflow: hidden;
    min-width: 240px;
}

.item-content {
    position: relative;
    background: white;
    display: flex;
    flex-direction: row;
    height: 72px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    max-width: 100%;
    overflow: hidden;
    min-width: 240px;
}
.item-content:hover {
    background: #f9f9f9;
}

.item-avatar {
    position: relative;
    padding: 0 15px 0 13px;
    justify-content: center;
    display: flex;
    align-items: center;
}

/* Avatar */
.item-avatar-content {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-avatar-content.large {
    width: 45px;
    height: 45px;
}

.item-avatar-content.circle {
    border-radius: 100%
}
.item-avatar-content .avatar{
    width: 100%;
    height: 100%;
}

.item-avatar img{
    width: 50px;
    height: 50px;
    border: none !important;
    background: transparent;
    border-radius: 100%;
    overflow: hidden;
    font-size: 10px;
    text-align: center;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Body */
.item-body{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;
    border-bottom: 1px solid rgba(0,0,0,.05);
    overflow: hidden;
}

.item-body-top{
    display: flex
}

.item-body-top-title{
    font-size: 16px;
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.item-body-top-time{
    font-size: 12px;
    color: rgba(0,0,0,0.4);
}

/* Footer */
.item-body-bottom{
    margin-top: 4px;
    display: flex;
}
.item-body-bottom-title{
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #555;
    font-size: 15px;

}
.item-body-bottom-tools{
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex;
    height: 0;
    opacity: 0;
    position: absolute;
    left: -999px;
    transition: height .5s ease, opacity 1s ease;
}
.item-body-bottom-tools-item-hidden-hover {
    height: 100%;
    opacity: 0.3;
    transition: .5s ease;
}
.item-body-bottom-status {
    margin-left: 3px;
}
.boxSendMsg>div {
  background: linear-gradient(to left, var(--boxSend), var(--boxSend2)) !important;
  color: var(--colorLetra) !important;
  pointer-events: all;

}
/* .rce-container-mbox.boxSendMsg,
.rce-container-mbox.boxRecieveMsg {
  display: initial !important;
} */

.rce-container-mbox.boxSendMsg,.rce-container-mbox.boxRecieveMsg{
  pointer-events: none;

}

.rce-mbox-right-notch {
  fill: var(--notchRight) !important;
}

.containerChat {
  display: flex;
  padding: 5px;
  border-top: 1px solid #dadada;
  position: -webkit-sticky;
  position: sticky;
  bottom: 5px;
  top: 100px;
  /* top: 0px; */
  left: calc(100% - 400px);
  right: 0;
  background: #ffffff;

}

.boxRecieveMsg>div {
  background: linear-gradient(to right, #dadada, #dadada60) !important;
  pointer-events: all;

}

.rce-mbox-left-notch {
  position: absolute;
  left: -14px;
  top: 0px;
  width: 15px;
  height: 15px;
  fill: #dadada !important;
}

.rce-mbox-photo {
  cursor: pointer;
}

.imgResizeMobile {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: calc(100vw - 140px);
  padding-left: calc(100vw - 300px);
  padding-right: calc(100vw - 300px);
  background: black;
  z-index: 6;
  min-height: 100vh !important;
}

.imgCloseResizeMobile {
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 40px;
}
/* 
.styles_scrollable-div__prSCv {
  max-height: 95%;
} */

*:focus-visible {
  outline: none;
}
.centerImageContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.messageBoxPersRight {
    float: right;
    margin-left: 5px;
    margin-right: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    max-width: 80%;
    padding: 10px 10px 0px 10px;
    min-width: 140px
    
}
.messageBoxPersLeft {
    float: left;
    margin-left: 20px;
    margin-right: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-width: 80%;
    padding: 10px 10px 0px 10px;
    min-width: 140px
    
}
.boxSendMsg>div {
    background: linear-gradient(to left, var(--boxSend), var(--boxSend2)) !important;
    color: var(--colorLetra) !important;
    pointer-events: all;
  
  }
.messages-container-mbox {
    flex-direction: column;
    display: block;
    overflow-x: hidden;
    min-width: 300px;
    margin-top: 10px;   
    border-radius: 5;
    /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .2); */
    border-top-left-radius: 0;
    margin-left: 20;
    margin-right: 5;
    margin-top: 3;
    flex-direction: 'column';
    margin-bottom: 3;
    padding-top: 7;
    padding-left: 9;
    padding-bottom: 8;
    padding-right: 9;
    /* min-width: 140; */
}
.fileBox, .audioBox {
    display: flex;
    align-items: center;
    background: #ededed;
    color: black;
    padding: 8px 5px;
    border-radius: 7px;
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 20%);
    cursor: pointer;
}

.audioBox{
    height: 60px
}

.fileBox{
    margin: -7px -3px 0px -5px;
    background: #0000002e;
    color: white
}
