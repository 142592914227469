.widgetStyle {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 6px 6px;
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  color: var(--colorLetra) !important;
  background: #ffffff;
  background-image: linear-gradient(var(--widgetContainer), var(--widgetContainer2)) !important;
  cursor: pointer; }

.widgetStyleText {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 6px 6px;
  min-width: 150px;
  height: 70px;
  background: #ffffff;
  background-image: linear-gradient(to right, var(--widgetContainer) 50%, var(--widgetContainer2)) !important;
  color: var(--colorLetra) !important;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 45px; }
