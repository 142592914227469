/* Container */
.container-item {
    flex-direction: column;
    display: block;
    overflow: hidden;
    min-width: 240px;
}

.item-content {
    position: relative;
    background: white;
    display: flex;
    flex-direction: row;
    height: 72px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    max-width: 100%;
    overflow: hidden;
    min-width: 240px;
}
.item-content:hover {
    background: #f9f9f9;
}

.item-avatar {
    position: relative;
    padding: 0 15px 0 13px;
    justify-content: center;
    display: flex;
    align-items: center;
}

/* Avatar */
.item-avatar-content {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-avatar-content.large {
    width: 45px;
    height: 45px;
}

.item-avatar-content.circle {
    border-radius: 100%
}
.item-avatar-content .avatar{
    width: 100%;
    height: 100%;
}

.item-avatar img{
    width: 50px;
    height: 50px;
    border: none !important;
    background: transparent;
    border-radius: 100%;
    overflow: hidden;
    font-size: 10px;
    text-align: center;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Body */
.item-body{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;
    border-bottom: 1px solid rgba(0,0,0,.05);
    overflow: hidden;
}

.item-body-top{
    display: flex
}

.item-body-top-title{
    font-size: 16px;
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.item-body-top-time{
    font-size: 12px;
    color: rgba(0,0,0,0.4);
}

/* Footer */
.item-body-bottom{
    margin-top: 4px;
    display: flex;
}
.item-body-bottom-title{
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #555;
    font-size: 15px;

}
.item-body-bottom-tools{
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex;
    height: 0;
    opacity: 0;
    position: absolute;
    left: -999px;
    transition: height .5s ease, opacity 1s ease;
}
.item-body-bottom-tools-item-hidden-hover {
    height: 100%;
    opacity: 0.3;
    transition: .5s ease;
}
.item-body-bottom-status {
    margin-left: 3px;
}