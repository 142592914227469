.boxSendMsg>div {
  background: linear-gradient(to left, var(--boxSend), var(--boxSend2)) !important;
  color: var(--colorLetra) !important;
  pointer-events: all;

}
/* .rce-container-mbox.boxSendMsg,
.rce-container-mbox.boxRecieveMsg {
  display: initial !important;
} */

.rce-container-mbox.boxSendMsg,.rce-container-mbox.boxRecieveMsg{
  pointer-events: none;

}

.rce-mbox-right-notch {
  fill: var(--notchRight) !important;
}

.containerChat {
  display: flex;
  padding: 5px;
  border-top: 1px solid #dadada;
  position: sticky;
  bottom: 5px;
  top: 100px;
  /* top: 0px; */
  left: calc(100% - 400px);
  right: 0;
  background: #ffffff;

}

.boxRecieveMsg>div {
  background: linear-gradient(to right, #dadada, #dadada60) !important;
  pointer-events: all;

}

.rce-mbox-left-notch {
  position: absolute;
  left: -14px;
  top: 0px;
  width: 15px;
  height: 15px;
  fill: #dadada !important;
}

.rce-mbox-photo {
  cursor: pointer;
}

.imgResizeMobile {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: calc(100vw - 140px);
  padding-left: calc(100vw - 300px);
  padding-right: calc(100vw - 300px);
  background: black;
  z-index: 6;
  min-height: 100vh !important;
}

.imgCloseResizeMobile {
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 40px;
}
/* 
.styles_scrollable-div__prSCv {
  max-height: 95%;
} */

*:focus-visible {
  outline: none;
}
.centerImageContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}