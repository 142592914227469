.messageBoxPersRight {
    float: right;
    margin-left: 5px;
    margin-right: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    max-width: 80%;
    padding: 10px 10px 0px 10px;
    min-width: 140px
    
}
.messageBoxPersLeft {
    float: left;
    margin-left: 20px;
    margin-right: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-width: 80%;
    padding: 10px 10px 0px 10px;
    min-width: 140px
    
}
.boxSendMsg>div {
    background: linear-gradient(to left, var(--boxSend), var(--boxSend2)) !important;
    color: var(--colorLetra) !important;
    pointer-events: all;
  
  }
.messages-container-mbox {
    flex-direction: column;
    display: block;
    overflow-x: hidden;
    min-width: 300px;
    margin-top: 10px;   
    border-radius: 5;
    /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .2); */
    border-top-left-radius: 0;
    margin-left: 20;
    margin-right: 5;
    margin-top: 3;
    flex-direction: 'column';
    margin-bottom: 3;
    padding-top: 7;
    padding-left: 9;
    padding-bottom: 8;
    padding-right: 9;
    /* min-width: 140; */
}
.fileBox, .audioBox {
    display: flex;
    align-items: center;
    background: #ededed;
    color: black;
    padding: 8px 5px;
    border-radius: 7px;
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 20%);
    cursor: pointer;
}

.audioBox{
    height: 60px
}

.fileBox{
    margin: -7px -3px 0px -5px;
    background: #0000002e;
    color: white
}